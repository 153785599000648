import { Autocomplete } from "@mui/material"
import {
  getFieldNameParts,
  getParentName,
  useForm,
} from "@w3rone/json-schema-form"
import type {
  FieldProps,
  AutocompleteFieldSchema,
} from "@w3rone/json-schema-form"
import { useAsyncEffect } from "@react-hook/async"
import { fetch } from "app/api"
import { useRouter } from "app/components/Router"
import { TextField } from "app/components/TextField"
import get from "lodash.get"
import * as R from "remeda"
import * as React from "react"
import { MaterialIcon } from "./MaterialIcon"
import { KeyboardArrowDown } from "@mui/icons-material"

export const AutocompleteField = ({
  label,
  schema,
  onChange,
  value = { [keywordFieldName]: "" },
  name,
  errors,
  description,
  id,
}: FieldProps<AutocompleteFieldSchema>) => {
  if (!isAutocompleteFieldValue(value)) {
    console.error("Invalid autocomplete value", name, value)
    return null
  }

  const fieldNameParts = getFieldNameParts(name)

  if (!fieldNameParts) {
    console.error(`Impossible to get field name parts for field ${name}`)
    return null
  }

  const router = useRouter()

  const form = useForm()

  const entityFieldName = R.last(fieldNameParts)

  if (!entityFieldName) {
    throw new Error("Can't get entity field name")
  }

  const request = useAsyncEffect<Array<AutocompleteResult>>(async () => {
    if (value.ac_keyword === "") {
      return []
    }

    let params = null

    if (schema.options.autocomplete.params) {
      const parentName = getParentName(name)

      params = R.mapValues(schema.options.autocomplete.params, (value) => {
        const fieldName = `${parentName}[${value}]`
        const fieldValue = get(form.value, fieldName)

        return fieldValue
      })
    }

    const routeParams = {
      entity: schema.options.autocomplete.entity,
      keyword: value.ac_keyword,
      params: params,
    }

    const response = await fetch(
      router.generate("app_api_autocomplete", routeParams),
    )

    const data = await response.json()

    return data
  }, [value.ac_keyword])

  return (
    <div>
      <Autocomplete
        popupIcon={
          schema.properties?.ac_keyword?.options.attr?.icon ? (
            <MaterialIcon
              icon={schema.properties.ac_keyword.options.attr.icon.toLowerCase()}
            />
          ) : (
            <KeyboardArrowDown fontSize="large" />
          )
        }
        sx={
          schema.properties?.ac_keyword?.options.attr?.icon
            ? { "& .MuiAutocomplete-popupIndicator": { transform: "none" } }
            : undefined
        }
        disabled={schema.readOnly}
        renderInput={(params) => {
          return (
            <TextField
              label={label}
              name={`${name}[${keywordFieldName}]`}
              description={description}
              errors={errors}
              id={id}
              inputProps={params.inputProps}
              {...params.InputProps}
              onChange={(e) =>
                onChange?.({
                  ...(value as AutocompleteFieldValue),
                  [keywordFieldName]: e.target.value,
                })
              }
            />
          )
        }}
        options={request.value || []}
        inputValue={value.ac_keyword || ""}
        loading={request.status === "loading"}
        getOptionLabel={(option) => option.name}
        filterOptions={(x) => x}
        onChange={(_event, selectedOption) => {
          onChange?.({
            [keywordFieldName]: selectedOption ? selectedOption.name : "",
            [entityFieldName]: selectedOption ? String(selectedOption.id) : "",
          })
        }}
        isOptionEqualToValue={(option, value) =>
          option.id === value.id && option.name === value.name
        }
      />
      <input
        type="hidden"
        name={`${name}[${entityFieldName}]`}
        value={value.ac_keyword ? value[entityFieldName] : ""}
        onChange={noop}
      />
      {schema.readOnly ? (
        <input
          type="hidden"
          name={`${name}[${keywordFieldName}]`}
          defaultValue={value.ac_keyword || ""}
        />
      ) : null}
    </div>
  )
}

const keywordFieldName = "ac_keyword"

type AutocompleteResult = {
  id: number
  name: string
}

function isAutocompleteFieldValue(
  value: unknown,
): value is AutocompleteFieldValue {
  return value === undefined || typeof value === "object"
}

type AutocompleteFieldValue =
  | {
      ac_keyword: string
      [key: string]: string
    }
  | undefined

const noop = () => {
  // intentionally does nothing
}
