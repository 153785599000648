import { Box, FormControl, FormLabel, Grid, Tooltip } from "@mui/material"
import { AutoField } from "@w3rone/json-schema-form"
import type {
  FieldProps,
  ValueLeaf,
  ObjectFieldSchema,
} from "@w3rone/json-schema-form"
import { DateFieldPropProvider } from "./DateField"
import { FormErrors } from "./FormErrors"
import { Info } from "@mui/icons-material"

export const DateTimeIntervalField = ({
  label,
  name,
  description,
  errors,
  value,
}: FieldProps<ObjectFieldSchema>) => {
  assertDateIntervalValue(value)

  return (
    <FormControl
      variant="standard"
      component="fieldset"
      error={errors.length > 0}
    >
      {label ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FormLabel component="legend">{label}</FormLabel>
          {description ? (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: description }} />}
            >
              <Info />
            </Tooltip>
          ) : null}
        </Box>
      ) : null}
      <FormErrors errors={errors} />
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={6}>
          <DateFieldPropProvider
            maxDate={value?.to ? new Date(value.to) : undefined}
          >
            <AutoField name={`${name}[from]`} />
          </DateFieldPropProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateFieldPropProvider
            minDate={value?.from ? new Date(value.from) : undefined}
          >
            <AutoField name={`${name}[to]`} />
          </DateFieldPropProvider>
        </Grid>
      </Grid>
    </FormControl>
  )
}

type DateIntervalValue = {
  from: string
  to: string
}

function assertDateIntervalValue(
  value: ValueLeaf | undefined,
): asserts value is DateIntervalValue {
  if (
    value &&
    !Array.isArray(value) &&
    typeof value === "object" &&
    value?.to === undefined &&
    value?.from === undefined
  ) {
    throw new Error("Given value is not a DateIntervalValue")
  }
}
