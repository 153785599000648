import type { FieldProps, StringFieldSchema } from "@w3rone/json-schema-form"
import { StringField } from "./StringField"
import { InputAdornment } from "@mui/material"

export const MoneyField = (props: FieldProps<StringFieldSchema>) => {
  return (
    <StringField
      {...props}
      endAdornment={<InputAdornment position="end">€</InputAdornment>}
    />
  )
}
