import { AutoField } from "@w3rone/json-schema-form"
import type {
  FieldProps,
  CollectionFieldSchema,
} from "@w3rone/json-schema-form"
import { FormErrors } from "./FormErrors"
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Tooltip,
} from "@mui/material"
import { getInitialValue } from "../getInitialValue"
import { AddBox, DeleteForever, Info } from "@mui/icons-material"

export const CollectionGridField = ({
  errors,
  label,
  description,
  value,
  schema,
  onChange,
  name,
}: FieldProps<CollectionFieldSchema>) => {
  assertCollectionValue(value)

  const handleAdd = () => {
    const newValue = [
      ...(value || []),
      Array.isArray(schema.items)
        ? undefined
        : getInitialValue(schema.items, null),
    ]

    onChange?.(newValue)
  }

  const handleRemove = (index: number) => {
    const defaultedValue = value || []

    onChange?.([
      ...defaultedValue.slice(0, index),
      undefined,
      ...defaultedValue.slice(index + 1),
    ])
  }

  return (
    <FormControl
      variant="standard"
      component="fieldset"
      error={errors.length > 0}
      fullWidth={true}
    >
      {label ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FormLabel component="legend">{label}</FormLabel>
          {description ? (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: description }} />}
            >
              <Info />
            </Tooltip>
          ) : null}
        </Box>
      ) : null}
      <FormErrors errors={errors} />
      <Box
        sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 2 }}
      >
        {Array.isArray(value)
          ? value.map((itemValue, index) => {
              const itemName = `${name}[${index}]`

              if (itemValue === undefined) {
                return null
              }

              return (
                <Box
                  key={itemName}
                  sx={{ display: "flex", gap: 3, alignItems: "center" }}
                >
                  <AutoField name={itemName} />
                  {schema.options.collection.allowDelete && !schema.readOnly ? (
                    <IconButton
                      aria-label="Supprimer"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleRemove(index)
                      }}
                      edge="start"
                    >
                      <DeleteForever />
                    </IconButton>
                  ) : null}
                </Box>
              )
            })
          : null}
        {schema.options.collection.allowAdd && !schema.readOnly ? (
          <Button startIcon={<AddBox />} type="button" onClick={handleAdd}>
            Ajouter un élément
          </Button>
        ) : null}
      </Box>
    </FormControl>
  )
}

function assertCollectionValue(
  value: any,
): asserts value is Array<any> | undefined {
  if (value && !Array.isArray(value)) {
    throw new Error("value is not a collection value")
  }
}
