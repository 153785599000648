import { AutoField } from "@w3rone/json-schema-form"
import type {
  FieldProps,
  CollectionFieldSchema,
} from "@w3rone/json-schema-form"
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Tooltip,
} from "@mui/material"
import { FormErrors } from "./FormErrors"
import { AddBox, DeleteForever, Info } from "@mui/icons-material"

export const CollectionTableField = ({
  errors,
  label,
  description,
  value,
  schema,
  onChange,
  name,
}: FieldProps<CollectionFieldSchema>) => {
  assertCollectionValue(value)

  if (Array.isArray(schema.items)) {
    return null
  }

  if (schema.items.type !== "object") {
    return null
  }

  const handleAdd = () => {
    onChange?.([...(value || []), {}])
  }

  const handleRemove = (index: number) => {
    const defaultedValue = value || []

    onChange?.([
      ...defaultedValue.slice(0, index),
      undefined,
      ...defaultedValue.slice(index + 1),
    ])
  }

  const highlightedProperties = Object.entries(schema.items.properties).filter(
    ([, propertySchema]) => propertySchema.options?.highlighted,
  )

  const otherProperties = Object.entries(schema.items.properties).filter(
    ([, propertySchema]) => !propertySchema.options?.highlighted,
  )

  return (
    <FormControl
      variant="standard"
      component="fieldset"
      error={errors.length > 0}
      fullWidth={true}
    >
      {label ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FormLabel component="legend">{label}</FormLabel>
          {description ? (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: description }} />}
            >
              <Info />
            </Tooltip>
          ) : null}
        </Box>
      ) : null}
      <FormErrors errors={errors} />
      <Stack spacing={2}>
        <TableContainer sx={{ maxHeight: "80vh" }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                {schema.options.collection.allowDelete && !schema.readOnly ? (
                  <TableCell sx={{ width: 50 }}></TableCell>
                ) : null}
                {[...highlightedProperties, ...otherProperties].map(
                  ([propertyName, propertySchema]) => (
                    <TableCell key={propertyName} sx={{ minWidth: "300px" }}>
                      {propertySchema.title}
                    </TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(value)
                ? value.map((itemValue, index) =>
                    itemValue === undefined ? null : (
                      <TableRow key={index}>
                        {schema.options.collection.allowDelete &&
                        !schema.readOnly ? (
                          <TableCell>
                            <IconButton
                              onClick={() => handleRemove(index)}
                              color="error"
                            >
                              <DeleteForever />
                            </IconButton>
                          </TableCell>
                        ) : null}
                        {[...highlightedProperties, ...otherProperties].map(
                          ([propertyName]) => (
                            <TableCell key={propertyName}>
                              <AutoField
                                name={`${name}[${index}][${propertyName}]`}
                              />
                            </TableCell>
                          ),
                        )}
                      </TableRow>
                    ),
                  )
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {schema.options.collection.allowAdd && !schema.readOnly ? (
          <Button startIcon={<AddBox />} onClick={handleAdd}>
            Ajouter un élément
          </Button>
        ) : null}
      </Stack>
    </FormControl>
  )
}

function assertCollectionValue(
  value: any,
): asserts value is Array<any> | undefined {
  if (value && !Array.isArray(value)) {
    throw new Error("value is not a collection value")
  }
}
