import { Box, FormControl, FormLabel, Stack, Tooltip } from "@mui/material"
import { styled } from "@mui/material/styles"
import { match } from "ts-pattern"
import { AutoField } from "@w3rone/json-schema-form"
import type {
  FormSchema,
  ObjectFieldSchema,
  FieldProps,
} from "@w3rone/json-schema-form"
import { FormErrors } from "./FormErrors"
import { Info } from "@mui/icons-material"

export const NestedField = ({
  schema,
  name,
  errors,
  label,
  description,
}: FieldProps<ObjectFieldSchema | FormSchema>) => {
  const hasOnlyHiddenFields = Object.values(schema.properties).every(
    (field) => field.options.widget === "hidden",
  )

  const WidgetWrapper = match(schema.options?.layout)
    .with("inline", () => InlineWrapper)
    .with("two-col", () => TwoColWrapper)
    .otherwise(() => DefaultWrapper)

  const style = { display: hasOnlyHiddenFields ? "none" : undefined }

  if (isFormSchema(schema)) {
    return (
      <Stack spacing={4} sx={style}>
        <FormErrors errors={errors} />
        <Box sx={{ marginTop: 4 }}>
          <WidgetWrapper>
            {Object.keys(schema.properties).map((fieldName) => {
              return (
                <AutoField
                  key={fieldName}
                  name={name ? `${name}[${fieldName}]` : fieldName}
                  required={schema.required.includes(fieldName)}
                />
              )
            })}
          </WidgetWrapper>
        </Box>
      </Stack>
    )
  }

  const visibleProperties = Object.entries(schema.properties).filter(
    ([, schema]) => schema.options.widget !== "hidden",
  )

  const hiddenProperties = Object.entries(schema.properties).filter(
    ([, schema]) => schema.options.widget === "hidden",
  )

  return (
    <FormControl component="fieldset" sx={style} fullWidth={true}>
      {label ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FormLabel component="legend">{label}</FormLabel>
          {description ? (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: description }} />}
            >
              <Info />
            </Tooltip>
          ) : null}
        </Box>
      ) : null}
      <FormErrors errors={errors} />
      <Box
        sx={{
          marginTop: 1,
        }}
      >
        <WidgetWrapper>
          {visibleProperties.map(([key], index) => (
            <Box
              key={key}
              gridColumn={
                index === visibleProperties.length - 1 && index % 2 === 0
                  ? "1 / -1"
                  : undefined
              }
            >
              <AutoField name={`${name}[${key}]`} />
            </Box>
          ))}
        </WidgetWrapper>
      </Box>
      <div>
        {hiddenProperties.map(([key]) => (
          <AutoField key={key} name={`${name}[${key}]`} />
        ))}
      </div>
    </FormControl>
  )
}

const DefaultWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(4),
}))

const TwoColWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    columnCount: 2,
    gap: theme.spacing(4),
    "& > [type=hidden]:first-child + *": {
      marginTop: "0 !important",
    },
    "& > * + *": {
      marginTop: theme.spacing(4) + "!important",
    },
  },
}))

const InlineWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  marginTop: 0,
  "& > *": {
    flexBasis: "100%",
    flexShrink: 1,
    flexGrow: 1,
  },
}))

const isFormSchema = (
  schema: FormSchema | ObjectFieldSchema,
): schema is FormSchema => {
  return typeof (schema as FormSchema).$id === "string"
}
