import { OutlinedInputProps } from "@mui/material"
import { ChangeEvent } from "react"
import type { FieldProps, StringFieldSchema } from "@w3rone/json-schema-form"
import { TextField } from "app/components/TextField"
import convertAttr from "react-attr-converter"
import * as R from "remeda"

export const StringField = ({
  label,
  value: valueProps = "",
  id,
  name,
  onChange,
  description,
  errors,
  schema,
  placeholder,
  endAdornment,
  defaultValue,
  readOnly = false,
}: FieldProps<StringFieldSchema> &
  Pick<OutlinedInputProps, "endAdornment" | "readOnly">) => {
  const value = !R.isNullish(valueProps)
    ? String(valueProps)
    : defaultValue
      ? undefined
      : ""

  if (schema.options.widget === "hidden") {
    return <input type="hidden" defaultValue={value} name={name} />
  }

  return (
    <div>
      <TextField
        errors={errors}
        id={id}
        label={label}
        name={name}
        value={value}
        defaultValue={defaultValue ? String(defaultValue) : undefined}
        description={description}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange?.(e.target.value)
        }
        inputProps={{
          type: widgetToType(schema.options.widget),
          readOnly: readOnly,
          ...R.mapKeys(schema.options.attr ?? {}, (key) => convertAttr(key)),
        }}
        multiline={schema.options.widget === "textarea"}
        maxRows={15}
        placeholder={placeholder}
        disabled={schema.readOnly}
        endAdornment={endAdornment}
      />
      {schema.readOnly ? (
        <input type="hidden" defaultValue={value} name={name} />
      ) : null}
    </div>
  )
}

const widgetToType = (widget: string) => {
  const mapping: Record<string, string> = {
    integer: "number",
    range: "number",
  }

  if (mapping[widget]) {
    return mapping[widget]
  }

  if (nativeTypes.includes(widget)) {
    return widget
  }

  return "text"
}

const nativeTypes = [
  "button",
  "checkbox",
  "color",
  "date",
  "datetime",
  "datetime-local",
  "email",
  "file",
  "hidden",
  "image",
  "month",
  "number",
  "password",
  "radio",
  "range",
  "reset",
  "search",
  "submit",
  "tel",
  "text",
  "time",
  "url",
  "week",
]
