import type { FieldProps, StringFieldSchema } from "@w3rone/json-schema-form"
import { isValid } from "date-fns/isValid"
import { createContext, Fragment, ReactNode, useContext } from "react"
import { DatePicker } from "app/components/DatePicker"
import { FormErrors } from "./FormErrors"
import { format } from "date-fns"

export const DateField = (props: FieldProps<StringFieldSchema>) => {
  const value = props.value ? new Date(String(props.value)) : null

  const propProviderContext = useContext(DateFieldPropContext)

  return (
    <Fragment>
      <DatePicker
        value={value}
        onChange={(date) =>
          props.onChange?.(date && isValid(date) ? date.toISOString() : "")
        }
        label={props.label}
        disabled={props.schema.readOnly}
        minDate={propProviderContext?.minDate}
        maxDate={propProviderContext?.maxDate}
        slotProps={{
          textField: {
            id: props.id,
            helperText: props.description,
            error: props.errors.length > 0,
          },
        }}
      />
      {props.errors ? <FormErrors errors={props.errors} /> : null}
      <input
        type="hidden"
        name={props.name}
        value={value ? format(value, "yyyy-MM-dd") : ""}
      />
    </Fragment>
  )
}

const DateFieldPropContext = createContext<
  { minDate?: Date; maxDate?: Date } | undefined
>(undefined)

export const DateFieldPropProvider = ({
  minDate,
  maxDate,
  children,
}: DateFieldPropProviderProps) => {
  return (
    <DateFieldPropContext.Provider value={{ minDate, maxDate }}>
      {children}
    </DateFieldPropContext.Provider>
  )
}

type DateFieldPropProviderProps = {
  minDate?: Date
  maxDate?: Date
  children: ReactNode
}
