import * as React from "react"

export const MaterialIcon = ({ icon }: { icon: string }) => {
  const IconComponent = icons.get(icon)

  React.useEffect(() => {
    if (!IconComponent) {
      console.warn(
        `No icon found for identifier "${icon}". Please check the mapping in MaterialIcon component`,
      )
    }
  }, [IconComponent, icon])

  if (!IconComponent) {
    return null
  }

  return (
    <React.Suspense fallback={null}>
      <IconComponent />
    </React.Suspense>
  )
}

const icons = new Map([
  ["home", React.lazy(() => import("@mui/icons-material/Home"))],
  [
    "accountcircle",
    React.lazy(() => import("@mui/icons-material/AccountCircle")),
  ],
  ["peoplealt", React.lazy(() => import("@mui/icons-material/PeopleAlt"))],
])
