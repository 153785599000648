import { Box } from "@mui/material"
import { AutoField } from "@w3rone/json-schema-form"
import type {
  CollectionFieldSchema,
  FieldProps,
} from "@w3rone/json-schema-form"

export const ReportQuestionPhotoCollectionField = ({
  value,
  name,
}: FieldProps<CollectionFieldSchema>) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { xs: "repeat(2, 1fr)", lg: "1fr" },
        gap: 2,
      }}
    >
      {Array.isArray(value)
        ? value.map((_item, index) => (
            <AutoField key={`${name}[${index}]`} name={`${name}[${index}]`} />
          ))
        : null}
    </Box>
  )
}
