import {
  LocalizationProvider,
  DateTimePicker as MUIDateTimePicker,
  DateTimePickerProps as MUIDateTimePickerProps,
} from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3"
import { fr } from "date-fns/locale/fr"

export const DateTimePicker = (props: MUIDateTimePickerProps<Date>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
      <MUIDateTimePicker
        {...props}
        displayWeekNumber={true}
        sx={{ width: "100%" }}
      />
    </LocalizationProvider>
  )
}
