import { useEffect, useRef } from "react"
import { AutoField, useForm } from "@w3rone/json-schema-form"
import type { ObjectFieldSchema, FieldProps } from "@w3rone/json-schema-form"
import { TextField } from "app/components/TextField"
import getCountryISO3 from "country-iso-2-to-3"

export const AddressField = ({
  label,
  name,
  value,
  onChange,
  schema,
  errors: errorsProps,
  description,
  id,
}: FieldProps<ObjectFieldSchema>) => {
  assertAddressValue(value)

  const inputRef = useRef<HTMLInputElement>()
  const autocompleteRef = useRef<google.maps.places.Autocomplete>()

  const form = useForm()

  const handlePlaceChange = (result: google.maps.places.PlaceResult) => {
    const newValue = getValueFromResult(result)

    onChange?.(newValue)
  }

  useEffect(() => {
    const gmapSdkElementId = "gmap-sdk"
    const gmapSdkElement = document.getElementById(gmapSdkElementId)

    if (gmapSdkElement) {
      return undefined
    }

    const initSearchBox = async () => {
      try {
        await loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${process.env.GMAP_API_KEY}&libraries=places`,
          document.head,
          gmapSdkElementId,
        )

        if (!inputRef.current) {
          throw new Error("No input to initialize searchbox")
        }

        autocompleteRef.current = new google.maps.places.Autocomplete(
          inputRef.current,
        )

        autocompleteRef.current.addListener("place_changed", () => {
          if (!autocompleteRef.current) {
            return
          }

          handlePlaceChange(autocompleteRef.current.getPlace())
        })
      } catch (err) {
        console.error(err)
      }
    }

    initSearchBox()
  }, [])

  const rawFieldErrors = form.errors ? form.errors[`${name}[raw]`] : []
  const errors = [...errorsProps, ...(rawFieldErrors || [])]

  return (
    <div>
      <TextField
        disabled={schema.readOnly}
        label={label}
        inputRef={inputRef}
        name={`${name}[raw]`}
        defaultValue={value?.formatted || ""}
        errors={errors}
        id={id}
        description={description}
      />
      {schema.readOnly ? (
        <input
          type="hidden"
          defaultValue={value?.formatted || ""}
          name={`${name}[raw]`}
        />
      ) : null}
      {Object.keys(schema.properties)
        .filter((key) => key !== "raw")
        .map((key) => (
          <AutoField key={key} name={`${name}[${key}]`} />
        ))}
    </div>
  )
}

const loadScript = (src: string, position: HTMLElement, id: string) => {
  const script = document.createElement("script")
  script.async = true
  script.id = id
  script.src = src

  const p = new Promise((resolve, reject) => {
    script.onload = resolve
    script.onerror = reject
  })

  position.appendChild(script)

  return p
}

function assertAddressValue(
  value: unknown,
): asserts value is AddressValue | undefined {
  if (value !== undefined) {
    if ((value as AddressValue).raw === undefined) {
      throw new Error("value is not an optionnal AddressValue")
    }
  }
}

type AddressValue = {
  raw: string
  formatted?: string
  city?: string
  country?: string
  nb?: string
  state?: string
  street?: string
  zipcode?: string
  coords?: { lat: string; lng: string }
}

const getValueFromResult = (
  result: google.maps.places.PlaceResult,
): AddressValue => {
  const countryCodeISO2 = result.address_components?.find((component) =>
    component.types.includes("country"),
  )?.short_name

  return {
    raw: result.formatted_address || "",
    formatted: result.formatted_address,
    city: result.address_components?.find((component) =>
      component.types.includes("locality"),
    )?.long_name,
    country: countryCodeISO2 ? getCountryISO3(countryCodeISO2) : undefined,
    nb: result.address_components?.find((component) =>
      component.types.includes("street_number"),
    )?.long_name,
    state: result.address_components?.find((component) =>
      component.types.includes("administrative_area_level_1"),
    )?.long_name,
    street: result.address_components?.find((component) =>
      component.types.includes("route"),
    )?.long_name,
    zipcode: result.address_components?.find((component) =>
      component.types.includes("postal_code"),
    )?.long_name,
    coords: result.geometry?.location
      ? {
          lat: String(result.geometry.location.lat()),
          lng: String(result.geometry.location.lng()),
        }
      : undefined,
  }
}
