import { FormHelperText } from "@mui/material"

export type FormErrorsProps = {
  errors: Array<string>
}

export const FormErrors = ({ errors }: FormErrorsProps) => {
  if (errors.length === 0) {
    return null
  }

  if (errors.length === 1) {
    return <FormHelperText error={true}>{errors[0]}</FormHelperText>
  }

  return (
    <FormHelperText
      error={true}
      component={"ul"}
      sx={{ paddingLeft: 0, my: 0, listStylePosition: "inside" }}
    >
      {errors.map((error) => (
        <li key={error}>{error}</li>
      ))}
    </FormHelperText>
  )
}
