import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { AddBox, DeleteForever, ExpandMore, Info } from "@mui/icons-material"
import { AutoField } from "@w3rone/json-schema-form"
import type {
  FieldProps,
  CollectionFieldSchema,
} from "@w3rone/json-schema-form"
import { FormErrors } from "./FormErrors"
import { SyntheticEvent, useState } from "react"
import { match, P } from "ts-pattern"
import { getInitialValue } from "../getInitialValue"

export const CollectionAccordionField = ({
  errors,
  label,
  description,
  value,
  schema,
  onChange,
  name,
}: FieldProps<CollectionFieldSchema>) => {
  assertCollectionValue(value)

  const [expandedItems, setExpandedItems] = useState<Set<number>>(new Set())

  const handleAdd = () => {
    const newValue = [
      ...(value || []),
      Array.isArray(schema.items)
        ? undefined
        : getInitialValue(schema.items, null),
    ]

    onChange?.(newValue)
    setExpandedItems((prevState) => {
      const newState = new Set(prevState)
      newState.add(newValue.length - 1)

      return newState
    })
  }

  const handleRemove = (index: number) => {
    const defaultedValue = value || []

    onChange?.([
      ...defaultedValue.slice(0, index),
      undefined,
      ...defaultedValue.slice(index + 1),
    ])
  }

  const handleExpandChange =
    (index: number) => (_event: SyntheticEvent, expanded: boolean) => {
      setExpandedItems((prevState) => {
        const newState = new Set(prevState)

        if (expanded) {
          newState.add(index)
        } else {
          newState.delete(index)
        }

        return newState
      })
    }

  return (
    <FormControl
      variant="standard"
      component="fieldset"
      error={errors.length > 0}
      fullWidth={true}
    >
      {label ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FormLabel component="legend">{label}</FormLabel>
          {description ? (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{ __html: description }} />}
            >
              <Info />
            </Tooltip>
          ) : null}
        </Box>
      ) : null}
      <FormErrors errors={errors} />
      <Stack spacing={2} sx={{ marginTop: 1 }}>
        <Box>
          {Array.isArray(value)
            ? value.map((itemValue, index) =>
                itemValue === undefined ? null : (
                  <Accordion
                    key={`${name}[${index}]`}
                    expanded={expandedItems.has(index)}
                    onChange={handleExpandChange(index)}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        {schema.options.collection.allowDelete &&
                        !schema.readOnly ? (
                          <IconButton
                            aria-label="Supprimer"
                            onClick={(e) => {
                              e.stopPropagation()
                              handleRemove(index)
                            }}
                            edge="start"
                          >
                            <DeleteForever />
                          </IconButton>
                        ) : null}
                        <ItemTitle
                          properties={schema.options.itemTitleProperties}
                          value={itemValue}
                          index={index}
                          schema={schema.items}
                        />
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <AutoField name={`${name}[${index}]`} />
                    </AccordionDetails>
                  </Accordion>
                ),
              )
            : null}
        </Box>
        {schema.options.collection.allowAdd && !schema.readOnly ? (
          <Button startIcon={<AddBox />} type="button" onClick={handleAdd}>
            Ajouter un élément
          </Button>
        ) : null}
      </Stack>
    </FormControl>
  )
}

function assertCollectionValue(
  value: any,
): asserts value is Array<any> | undefined {
  if (value && !Array.isArray(value)) {
    throw new Error("value is not a collection value")
  }
}

type ItemTitleProps = {
  properties?: Array<string> | null
  value: any
  index: number
  schema: CollectionFieldSchema["items"]
}

const ItemTitle = ({ properties, value, index, schema }: ItemTitleProps) => {
  let title = `Item #${index + 1}`

  if (!Array.isArray(schema) && schema.type !== "object") {
    title = value?.toString() ?? ""
  } else if (!properties || properties.length === 0) {
    title = match(value)
      .with(
        P.union(P.bigint, P.number, P.string, P.symbol, P.boolean),
        (value) => value.toString(),
      )
      .otherwise(() => `Item #${index + 1}`)
  } else {
    title = properties
      .map((property) => value?.[property])
      .filter(Boolean)
      .join(" - ")
  }

  return <Typography>{title}</Typography>
}
