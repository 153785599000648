import {
  LocalizationProvider,
  DatePicker as MUIDatePicker,
  DatePickerProps as MUIDatePickerProps,
} from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3"
import { fr } from "date-fns/locale/fr"

export const DatePicker = (props: MUIDatePickerProps<Date>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
      <MUIDatePicker
        {...props}
        displayWeekNumber={true}
        sx={{ width: "100%" }}
      />
    </LocalizationProvider>
  )
}
