import { forwardRef, Ref } from "react"
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
  Tooltip,
} from "@mui/material"
import { FormErrors } from "app/components/Form/fields/FormErrors"
import { Info } from "@mui/icons-material"

export const TextField = forwardRef(function TextField(
  {
    errors,
    id,
    label,
    value,
    onChange,
    description,
    inputProps,
    ...props
  }: TextFieldProps,
  ref: Ref<any>,
) {
  return (
    <FormControl
      variant="outlined"
      error={errors && errors.length > 0}
      fullWidth={true}
    >
      {label ? (
        <InputLabel htmlFor={id}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <span>{label}</span>
            {description ? (
              <Tooltip
                title={
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                }
              >
                <Info />
              </Tooltip>
            ) : null}
          </Box>
        </InputLabel>
      ) : null}
      <OutlinedInput
        id={id}
        value={value}
        onChange={onChange}
        label={label}
        inputProps={inputProps}
        {...props}
        ref={ref}
        fullWidth={true}
        minRows={3}
      />
      {errors ? <FormErrors errors={errors} /> : null}
    </FormControl>
  )
})

export type TextFieldProps = {
  errors?: Array<string>
  id: string
  description?: string
} & Pick<
  OutlinedInputProps,
  | "endAdornment"
  | "onChange"
  | "inputRef"
  | "defaultValue"
  | "multiline"
  | "maxRows"
  | "placeholder"
  | "value"
  | "inputProps"
  | "label"
  | "name"
  | "disabled"
>
