import { Autocomplete, AutocompleteProps } from "@mui/material"
import { TextField, TextFieldProps } from "app/components/TextField"
import { useState } from "react"

export const Select = ({
  name,
  label,
  options,
  value: valueProps,
  onChange,
  multiple,
  placeholder,
  id,
  errors,
  defaultValue,
  getOptionDisabled,
  isNullable = false,
  description,
  disabled,
  renderTags,
  popupIcon,
}: SelectProps) => {
  const [value, setValue] = useState<typeof valueProps>(
    valueProps || defaultValue,
  )

  const handleChange: SelectProps["onChange"] = (e, value, reason, details) => {
    setValue(value)

    onChange?.(e, value, reason, details)
  }

  return (
    <div>
      <Autocomplete
        popupIcon={popupIcon ? popupIcon : undefined}
        sx={
          popupIcon
            ? { "& .MuiAutocomplete-popupIndicator": { transform: "none" } }
            : undefined
        }
        ChipProps={{ size: "small" }}
        disabled={disabled}
        options={options}
        renderTags={renderTags}
        renderInput={(params) => {
          return (
            <TextField
              id={id}
              label={label}
              placeholder={placeholder}
              {...params.InputProps}
              inputProps={params.inputProps}
              errors={errors}
              description={description}
              disabled={disabled}
            />
          )
        }}
        onChange={handleChange}
        openOnFocus
        multiple={multiple}
        value={multiple ? (Array.isArray(value) ? value : []) : value || null}
        getOptionDisabled={getOptionDisabled}
        clearIcon={isNullable ? undefined : null}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        disableCloseOnSelect={multiple}
      />
      <select
        style={{ display: "none" }}
        name={multiple ? `${name}[]` : name}
        value={
          multiple
            ? Array.isArray(value)
              ? value.map((v) => v.value)
              : []
            : value
            ? Array.isArray(value)
              ? ""
              : value.value
            : ""
        }
        onChange={noop}
        multiple={multiple}
      >
        <option value=""></option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.value}
          </option>
        ))}
      </select>
    </div>
  )
}

export type SelectProps = Pick<
  AutocompleteProps<Option, boolean | undefined, false, false>,
  | "options"
  | "value"
  | "multiple"
  | "onChange"
  | "defaultValue"
  | "getOptionDisabled"
  | "renderTags"
  | "popupIcon"
> &
  Omit<TextFieldProps, "onChange"> & { isNullable?: boolean }

export type Option = {
  label: string
  value: string
  preferred: boolean
}

const noop = () => {
  // intentionnaly does nothing
}
