import type { FieldProps, StringFieldSchema } from "@w3rone/json-schema-form"
import { isValid } from "date-fns/isValid"
import { createContext, Fragment, ReactNode, useContext } from "react"
import { fromZonedTime } from "date-fns-tz"
import { DateTimePicker } from "app/components/DateTimePicker"
import { FormErrors } from "./FormErrors"

export const DateTimeField = (props: FieldProps<StringFieldSchema>) => {
  const value = props.value ? new Date(String(props.value)) : null

  const propProviderContext = useContext(DateFieldPropContext)
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

  return (
    <Fragment>
      <DateTimePicker
        disabled={props.schema.readOnly}
        label={props.label}
        value={value}
        onChange={(date) =>
          props.onChange?.(date && isValid(date) ? date.toISOString() : "")
        }
        minDate={propProviderContext?.minDate}
        maxDate={propProviderContext?.maxDate}
        slotProps={{
          textField: {
            id: props.id,
            helperText: props.description,
            error: props.errors.length > 0,
          },
        }}
      />
      {props.errors ? <FormErrors errors={props.errors} /> : null}
      <input
        type="hidden"
        name={props.name}
        value={value ? fromZonedTime(value, timeZone).toISOString() : ""}
        onChange={noop}
      />
    </Fragment>
  )
}

const DateFieldPropContext = createContext<
  { minDate?: Date; maxDate?: Date } | undefined
>(undefined)

export const DateFieldPropProvider = ({
  minDate,
  maxDate,
  children,
}: DateFieldPropProviderProps) => {
  return (
    <DateFieldPropContext.Provider value={{ minDate, maxDate }}>
      {children}
    </DateFieldPropContext.Provider>
  )
}

type DateFieldPropProviderProps = {
  minDate?: Date
  maxDate?: Date
  children: ReactNode
}

const noop = () => {
  // Intentionally does nothing
}
